import React, { PropsWithChildren, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Dialog, Skeleton, Typography } from "@mui/material";
import { FHNextImage } from "../FHNextImage";
import CloseIcon from "@mui/icons-material/Close";
import { useCookies } from "react-cookie";
import { MemoizedVideo } from "../MemoizedVideo";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    maxWidth: 690,
  },
  closeButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    backgroundColor: theme.palette.common.white,
    width: 40,
    height: 40,
    borderRadius: "50%",
    right: 24,
    top: 24,
    zIndex: 2,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      right: 16,
      top: 16,
    },
  },
  image: {
    position: "relative",
    minHeight: 300,
  },
  content: {
    padding: theme.spacing(3.2, 4),
    display: "flex",
    flexDirection: "column",
    gap: 8,
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1.6, 2.4),
    },
    "& h2": {
      fontSize: 40,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      lineHeight: "44px",
      [theme.breakpoints.down("md")]: {
        fontSize: 30,
        lineHeight: "36px",
      },
    },
    "& p": {
      fontSize: 18,
      lineHeight: "27px",
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        lineHeight: "24px",
      },
    },
  },
  action: {
    marginTop: 16,
    [theme.breakpoints.down("md")]: {
      marginTop: 8,
    },
    "& a": {
      fontSize: 18,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 600,
      backgroundColor: "#111111",
      color: theme.palette.common.white,
      borderRadius: 5,
      padding: theme.spacing(1.4, 1.6),
      "&:hover": {
        color: theme.palette.common.white,
        backgroundColor: "#111111",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  buttonLight: {
    "& a": {
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  videoContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0,
    overflow: "hidden",
    width: "100%",
    height: "100%",
    minHeight: "100%",
    "& video": {
      width: "100%",
    },
  },
  heroVideo: {
    minWidth: "100%",
    height: "100%",
    backgroundSize: "cover",
    transition: "1s opacity",
    objectFit: "fill",
  },
}));

type LocationPromoPopUpProps = {
  title: string;
  discription?: JSX.Element;
  imgSrc?: string;
  ctaLabel?: string;
  ctaUrl?: string;
  videoUrl?: string;
  autoplayVideo?: boolean;
  cookieName?: string;
  buttonStyle?: "light" | "dark";
};

export const LocationPromoPopUp: React.FC<LocationPromoPopUpProps> = ({
  imgSrc,
  title,
  discription,
  ctaLabel,
  ctaUrl,
  videoUrl,
  autoplayVideo,
  cookieName,
  buttonStyle = "dark",
}: PropsWithChildren<LocationPromoPopUpProps>) => {
  const classes = useStyles();
  const video = useRef<HTMLVideoElement>(null);
  const [cookies, setCookie] = useCookies([cookieName]);
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const handleCookie = () => {
    setIsDialogOpen(false);
    !cookies?.cookieName && setCookie(cookieName, true);
  };

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={handleCookie}
        classes={{
          paper: classes.paperRoot,
        }}
      >
        <div className={classes.image}>
          <div onClick={handleCookie} className={classes.closeButton}>
            <CloseIcon />
          </div>
          {!videoUrl && (
            <FHNextImage
              src={imgSrc}
              alt=""
              isStaticImg
              layout="fill"
              objectFit="cover"
            />
          )}
          {videoUrl && (
            <div className={classes.videoContainer}>
              <MemoizedVideo
                {...{
                  src: videoUrl + "#t=0.001",
                  className: classNames(classes.heroVideo),
                  innerRef: video,
                  autoPlay: autoplayVideo,
                }}
              />
            </div>
          )}
        </div>
        <div className={classes.content}>
          <Typography variant="h2">{title}</Typography>
          <Typography>{discription}</Typography>
          <div
            className={classNames(classes.action, {
              [classes.buttonLight]: buttonStyle === "light",
            })}
          >
            <Button href={ctaUrl} onClick={handleCookie}>
              {ctaLabel}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
